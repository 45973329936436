<script setup>
// store
const store = useLoginStore()

// login
const login = async () => {
  store.processing = true
}
</script>

<template>
  <form @submit.prevent="login">
    <div class="row row-cols-1 gy-3 px-3">
      <div class="col">
        <div class="form-group has-icon">
          <span class="text-secondary form-icon"><i class="fas fa-user" /></span>
          <input
            v-model="store.user.username" class="form-control form-control-lg text-secondary bg-white" type="text"
            placeholder="Username" required name="username" autofocus autocomplete="on"
          >
        </div>
      </div>
      <div class="col">
        <div class="form-group has-icon">
          <span class="text-secondary form-icon"><i class="fas fa-key" /></span>
          <input
            v-model="store.user.password" class="form-control form-control-lg text-secondary bg-white" type="password"
            placeholder="Passwort" required name="password" autocomplete="on"
          >
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg w-100" type="submit">
          <span>Login</span>
        </button>
      </div>
    </div>
  </form>
</template>
