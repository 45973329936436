<script setup>
// store
const store = useLoginStore()

// login
onMounted(async () => { if (!await store.login()) store.showError('Login fehlgeschlagen.') })
</script>

<template>
  <div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<style scoped>
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
</style>
