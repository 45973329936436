<script setup>
import Modal from '~/components/core/Modal.vue'

// refs
const modal = ref(null)
const message = ref('')

const options = {
  backdrop: 'static',
  keyboard: false,
  focus: false,
}

const show = (text = '') => {
  modal.value.toggle()
  message.value = text
}
const hide = () => modal.value.toggle()

defineExpose({ show, hide })
</script>

<template>
  <Modal ref="modal" name="errorModal" :options="options">
    <form @submit.prevent="confirm">
      <div class="modal-header bg-primary">
        <div><i class="fas fa-exclamation-circle me-1" /><span>Fehler</span></div><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      </div>
      <div class="modal-body text-center bg-secondary">
        <h2 class="m-1">
          {{ message }}
        </h2>
      </div>
      <div class="modal-footer bg-secondary border-secondary p-0">
        <button type="submit" class="btn btn-warning btn-lg flex-grow-1 p-3 m-0" data-bs-dismiss="modal">
          Schließen
        </button>
      </div>
    </form>
  </Modal>
</template>
