<script setup>
import { bootstrap } from '@msc/lib.bootstrap'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  options: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  onShow: {
    type: Function,
    required: false,
  },
  onHide: {
    type: Function,
    required: false,
  },
})

const modal = ref(null)
const modalElement = ref(null)

const show = () => modal.value.show()
const hide = () => modal.value.hide()
const toggle = () => modal.value.toggle()

onMounted(() => {
  // register modal
  modal.value = new bootstrap.Modal(
    document.getElementById(props.name),
    props.options,
  )
  // if onShow is defined, register it
  modalElement.value.addEventListener('show.bs.modal', (event) => {
    props.onShow && props.onShow()
  })
  // if onHide is defined, register it
  modalElement.value.addEventListener('hide.bs.modal', (event) => {
    props.onHide && props.onHide()
  })
})
defineExpose({ toggle, show, hide })
</script>

<template>
  <div :id="props.name" ref="modalElement" role="dialog" class="modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>
