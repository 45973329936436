<script setup>
import Modal from '~/components/core/Modal.vue'

// refs
const modal = ref(null)

// store
const store = useLoginStore()

const options = {
  backdrop: 'static',
  keyboard: false,
  focus: false,
}

const show = () => modal.value.toggle()
const hide = () => modal.value.toggle()

const confirm = () => {
  hide()
}

defineExpose({ show, hide })
</script>

<template>
  <Modal ref="modal" name="qrcodeModal" :options="options">
    <form @submit.prevent="confirm">
      <div class="modal-header bg-primary">
        <div><span class="modal-title">QRCode</span></div>
      </div>
      <div class="modal-body bg-secondary text-center p-4">
        <img :src="store.qrcode.picbase64" alt="Base64 Image">
      </div>
      <div class="modal-footer bg-primary p-0">
        <button type="submit" class="btn btn-warning btn-lg flex-grow-1 p-3 m-0">
          Bestätigen
        </button>
      </div>
    </form>
  </Modal>
</template>
