<script setup>
import QRCodeModal from '~/components/modal/QRCodeModal.vue'

// store
const store = useLoginStore()

const qrcodeModal = ref(null)
// confirm
const confirm = () => {
  store.user.TwoFAconfirmed = true
}

const generate = async () => {
  if (!await store.getQRCode()) store.showError('Login fehlgeschlagen.')
  qrcodeModal.value?.show('QRCode')
}
</script>

<template>
  <form @submit.prevent="confirm">
    <div class="row row-cols-1 gy-3 px-3">
      <div class="col">
        <div class="form-group">
          <h5 class="text-left card-text">
            Einmalkennwort (2-Faktor Authentifizierung)
          </h5>
        </div>
      </div>
      <div class="col">
        <div class="form-group has-icon">
          <span class="text-secondary form-icon"><i class="fas fa-key" /></span>
          <input
            v-model="store.user.password2fa" class="form-control form-control-lg text-secondary bg-white" type="text" required
          >
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg w-100" type="submit">
          <span>Confirm</span>
        </button>
      </div>
      <div v-if="store.needQRCode" class="col">
        <button class="btn btn-primary btn-block btn-lg w-100" type="button" @click="generate">
          <span>Generate QR-Code</span>
        </button>
        <QRCodeModal ref="qrcodeModal" />
      </div>
    </div>
  </form>
</template>

<style>
.card-text {
  color: #2e4457;
}
</style>
