import { theme } from '@msc/lib.bootstrap'
import App from './App.vue'
import axios from '~/utils/axios'

async function init() {
  const token = useStorage('msc.token')
  const locked = useStorage('msc.locked')

  // clear session
  const clearSession = () => {
    locked.value = null
    token.value = null
  }

  // check session
  if (token.value) {
    try {
      console.log(`Refreshing session... ${token.value}`)
      const { data } = await axios.post('auth/refresh', { token: token.value })
      if (!data.refresh) throw new Error('Invalid refresh token')
      token.value = data.refresh
      window.location.reload()
      return
    } catch (error) {
      clearSession()
    }
  }

  // create app
  await theme.load('berthold')
  const pinia = createPinia()
  createApp(App).use(pinia).mount('#app')
}

init()
