<script setup>
// store
const store = useLoginStore()
store.database.selected = store.database.list[0].value

// confirm
const confirm = async () => {
  store.database.confirmed = true
  localStorage.setItem('database', store.database.selected)
  if (!await store.loadDomains()) store.showError('Login fehlgeschlagen.')
}
</script>

<template>
  <form @submit.prevent="confirm">
    <div class="row row-cols-1 gy-3 px-3">
      <div class="col">
        <div class="form-group has-icon">
          <span class="text-secondary form-icon"><i class="fas fa-database" /></span>
          <select v-model="store.database.selected" class="form-select form-control-lg text-secondary bg-white" required>
            <option v-for="db in store.database.list" :key="db" :value="db.value">
              {{ db.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg w-100" type="submit">
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </form>
</template>
