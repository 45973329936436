import { msc } from '@msc/lib.bridge'
import { axios } from '~/utils/axios'

export const useLoginStore = defineStore('login', () => {
  // state
  const config = ref({
    nfc: false,
  })
  const processing = ref(false)
  const nfc = ref(null)
  const user = ref({
    username: '',
    password: '',
    password2fa: '',
    domain: '',
    TwoFAconfirmed: false,
    confirmed: false,
  })
  const database = ref({
    confirmed: false,
    selected: null,
    list: null,
  })
  const domain = ref({
    confirmed: false,
    selected: null,
    list: null,
  })
  const qrcode = ref({
    picbase64: null,
    confirmed: false,
  })

  // getters
  const isProcessing = computed(() => processing.value)
  const needDatabase = computed(() => database.value?.list.length > 1 && !database.value.confirmed)
  const selectedDatabase = computed(() => database.value.selected)
  const needDomain = computed(() => !domain.value.confirmed)
  const needTwoFA = computed(() => !user.value.TwoFAconfirmed)
  const needQRCode = computed(() => !qrcode.value.confirmed)

  // actions
  const showError = (error) => { console.error(error) }

  const loadConfig = async () => {
    try {
      // get config
      const { data } = await axios.post('auth/config')
      // if there is a config merge it with the default config
      if (data.config) config.value = Object.assign(config.value, data.config)
    } catch (error) {
      console.warn('no config found')
    }
  }

  const enabled_2Fa = async () => {
    try {
      // check if 2FA is active
      const { data } = await axios.post('auth/enabled_2Fa', {
        nfc: nfc.value,
        username: user.value.username,
        password: user.value.password,
        password2fa: user.value.password2fa,
        database: database.value.selected,
        domain: domain.value.selected,
      })
      if (!data.TwoFA) throw new Error('2FA Abfrage fehlgeschlagen')
      if (!data.TwoFA.enabled)
        user.value.TwoFAconfirmed = true
      else
        console.log('2FA disabled')
      qrcode.value.confirmed = data.TwoFA.bSecretKey
      return true
    } catch (error) {
      setTimeout(() => {
        nfc.value = null
        processing.value = false
        database.value.confirmed = false
        user.value.TwoFAconfirmed = false
        qrcode.value.confirmed = false
        domain.value.confirmed = false
        console.error(error)
      }, 500)
      return false
    }
  }

  const loadDomains = async () => {
    try {
      // get existing UserTables
      const { data } = await axios.post('auth/domains', {
        database: database.value.selected,
      })
      console.log('Domains: ', data.domains.length)
      console.log(data.domains)
      if (!data.domains.length) throw new Error('no User found')
      domain.value.list = data.domains
      domain.value.selected = domain.value.list[0].owner
      console.log('Selected Domain: ', domain.value.selected)
      if (domain.value.selected && domain.value.list.length === 1) {
        console.log('Domain confirmed')
        user.value.domain = domain.value.selected
        domain.value.confirmed = true
        await enabled_2Fa()
      }
      return true
    } catch (error) {
      setTimeout(() => {
        nfc.value = null
        processing.value = false
        database.value.confirmed = false
        domain.value.confirmed = false

        console.error(error)
      }, 500)
      return false
    }
  }

  const loadDatabases = async () => {
    try {
      // get all available databases
      const { data } = await axios.post('auth/databases')
      if (!data.databases.length) throw new Error('no databases found')
      // set database list
      if (data.databases.length === 1) {
        database.value.list = data.databases
        database.value.selected = data.databases[0].value
        database.value.confirmed = true
        await loadDomains()
      }
      database.value.list = data.databases
      console.log('DBSTORAGE: ', data.DBSTORAGE)
      // check if localStorage has db and if it still exists then use it as default
      let localDB = localStorage.getItem('database')
      console.log('LocalDB: ', localDB)
      console.log('Database: ', database.value.list)
      if (localDB) localDB = database.value.list.find(db => db.value === localDB)
      if (localDB && data.DBSTORAGE) {
        database.value.selected = localDB.value
        database.value.confirmed = true
      }
      return true
    } catch (error) {
      console.error(error)
    }
  }

  const getQRCode = async () => {
    try {
      const { data } = await axios.post('auth/getQRCode', {
        username: user.value.username,
        domain: domain.value.selected,
        database: database.value.selected,
      })
      if (!data.QRcode) throw new Error('QRCode Generierung fehlgeschlagen.')
      qrcode.value.picbase64 = data.QRcode
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const loginWithNfc = async () => {
    try {
      // check if nfc is supported
      if (!await msc.plugins.nfc.isSupported()) return
      // get id from nfc tag
      const id = await msc.plugins.nfc.read()
      // check if id is valid
      if (!id) {
        loginWithNfc()
        throw new Error('ID konnte nicht gelesen werden')
      }
      // process login
      nfc.value = id
      processing.value = true
    } catch (error) {
      showError(error)
    }
  }

  const login = async () => {
    try {
      const { data } = await axios.post(`auth/${nfc.value ? 'nfc' : 'login'}`, {
        nfc: nfc.value,
        username: user.value.username,
        password: user.value.password,
        password2fa: user.value.password2fa,
        database: database.value.selected,
        domain: domain.value.selected,
      })
      if (data.token && data.cookie) {
        localStorage.setItem('msc.token', data.token)
        window.location.reload()
        console.log('Login successful')
        return true
      }
    } catch (error) {
      setTimeout(() => {
        nfc.value = null
        processing.value = false
        database.value.confirmed = false
        domain.value.confirmed = false
        user.value.confirmed = false
        user.value.TwoFAconfirmed = false
        qrcode.value.confirmed = false
      }, 500)
      loginWithNfc()
      return false
    }
  }

  return {
    config,
    processing,
    user,
    database,
    domain,
    qrcode,
    showError,
    loadConfig,
    loadDatabases,
    loadDomains,
    login,
    loginWithNfc,
    enabled_2Fa,
    getQRCode,
    isProcessing,
    needDatabase,
    needDomain,
    needTwoFA,
    needQRCode,
    selectedDatabase,
    /*  getDatabases, */
  }
})
