import axios from 'axios'
import { msc } from '@msc/lib.bridge'

// base url
const url = new URL(msc?.Frame?.Data?.Origin() || window.location.origin)

const instance = axios.create({
  withCredentials: true,
  baseURL: import.meta.env.MODE === 'development' ? 'http://localhost:7004' : url.origin,
  timeout: 1000 * 60,
})

export default instance
export { instance as axios }
