<script setup>
// store
const store = useLoginStore()
// store.domain.selected = store.domain.list[0].owner

// confirm
const confirm = async () => {
  store.domain.confirmed = true
  store.user.domain = store.domain.selected
  /*   const selectedUser = store.user.list.find(user => user.domain === store.user.selected)
  store.user.key = selectedUser ? selectedUser.key : null */
  if (!await store.enabled_2Fa()) store.showError('Login Fehlgeschlagen.')
}

// onMounted(async () => { if (!await store.checkUserTables()) store.showError('Login fehlgeschlagen.') })
</script>

<template>
  <form @submit.prevent="confirm">
    <div class="row row-cols-1 gy-3 px-3">
      <div class="col">
        <div class="form-group has-icon">
          <span class="text-secondary form-icon"><i class="fas fa-swatchbook" /></span>
          <select v-model="store.domain.selected" class="form-select form-control-lg text-secondary bg-white" required>
            <option v-for="domain in store.domain.list" :key="domain" :value="domain.owner">
              {{ domain.domain }}
            </option>
          </select>
        </div>
      </div>
      <div class="col">
        <button class="btn btn-primary btn-block btn-lg w-100" type="submit">
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </form>
</template>
