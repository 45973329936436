<script setup>
import DomainForm from '~/components/DomainForm.vue'
import LoginForm from '~/components/LoginForm.vue'
import DatabaseForm from '~/components/DatabaseForm.vue'
import LoadingForm from '~/components/LoadingForm.vue'
import ErrorModal from '~/components/modal/ErrorModal.vue'
import TwoFAForm from '~/components/TwoFAForm.vue'

// store
const store = useLoginStore()

// refs
const cardBody = ref(null)
const errorModal = ref(null)

// error
store.showError = error => errorModal.value?.show(error)

// params
const params = new URLSearchParams(window.location.search)
if (params.has('username') && params.has('password')) {
  store.user.username = params.get('username')
  store.user.password = params.get('password')
  store.database.selected = params.get('database')
  store.processing = true
}

// form
const form = {
  Login: LoginForm,
  Database: DatabaseForm,
  Loading: LoadingForm,
  Domain: DomainForm,
  TwoFA: TwoFAForm,
}

// selected form
const selectedForm = computed(() => {
  if (store.isProcessing && store.needDatabase) return 'Database'
  if (store.isProcessing && store.needDomain) return 'Domain'
  if (store.isProcessing && store.needTwoFA) return 'TwoFA'
  if (store.isProcessing) return 'Loading'
  return 'Login'
})

// mounted
onMounted(async () => {
  if (!await store.loadDatabases()) store.showError('Datenbanken konnten nicht geladen werden')
  if (!store.needDatabase)
    if (!await store.loadDomains()) store.showError('Domains konnten nicht geladen werden fehlgeschlagen.')
  await store.loginWithNfc()
})
</script>

<template>
  <div class="wrapper bg-berthold">
    <ErrorModal ref="errorModal" />
    <div class="card bg-secondary shadow m-auto card-login">
      <div class="card-header bg-primary shadow-sm">
        <h5 class="text-left p-1 m-0">
          <i class="fa-solid fa-circle-user me-2" />Sign-In
        </h5>
      </div>
      <div ref="cardBody" class="card-body bg-white d-flex align-items-center">
        <Transition name="fade" mode="out-in" duration="200">
          <component :is="form[selectedForm]" class="w-100 m-0 p-0" />
        </Transition>
      </div>
      <div class="card-footer text-center text-secondary bg-light">
        <small>© Berthold 2024</small>
      </div>
    </div>
  </div>
</template>

<style>
.wrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-login {
  width: 33rem;
}
.card-login .has-icon {
  display: flex;
  align-items: center;
}
.card-login .form-control::placeholder {
  color: #2e4457b7;
}
.card-login .has-icon .form-control, .card-login .has-icon .form-select {
/*   text-transform: uppercase; */
  padding-left: 3rem;
  border: 1px solid #ced4da;
  font-size: 1.125rem;
}
.card-login .has-icon .form-control:focus, .card-login .has-icon .form-select:focus {
  color: #495057;
  background-color: #fff;
  border-color: #8da9c3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72 106 136 / 25%);
  box-shadow: 0 0 0 0.2rem rgb(72 106 136 / 25%);
}
.card-login .has-icon .form-icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-login .card-body {
  height: 250px;
}
.card-login .card-footer {
  font-size: 0.8rem;
}
#btn-login{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
}
#btn-login.loading {
  font-size: 0;
}
#btn-login.loading .spinner-border {
  color: #fff;
  display: block;
  font-size: initial;
}
@media (max-width:1400px) {
  .card-login{
    width: 30rem;
  }
}
@media (max-width:1100px) {
  .card-login{
    width: 27rem;
  }
}
@media (max-width:800px){
  .card-login{
    width: 25rem;
  }
}
@media (max-width:500px) {
  .card-login{
    width: 23rem;
  }
}
</style>
